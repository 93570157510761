<template>
  <div
    class="container p-0 m-0 container-fluid d-flex flex-column c1-onboarding-home-container"
  >
    <div class="content-wrapper bg-wrapper" style="background-color: #133844">
      <InvitationExpiredModal />
      <Header header-layout="green" />
      <client-only>
        <LazyServiceAnnouncement />
      </client-only>
      <div class="container-fluid px-0">
        <div class="home-wrapper">
          <div class="d-flex mt-4 mt-md-0">
            <div class="position-relative w-100">
              <ResponsiveImage
                file="homepage-hero.webp"
                mode="resolution"
                :img="imagesProps['background-home-page']"
                :screens="heroSectionScreens"
                pc-image
              />
              <div class="main-content text-center text-md-start">
                <h1 class="heading1 mt-2 mt-sm-4 mt-md-0">
                  {{ $t('ONBOARDING.HOME.HEADING1') }}
                  <span class="heading2">{{
                    $t('ONBOARDING.HOME.HEADING2')
                  }}</span>
                </h1>
                <h2>{{ $t('ONBOARDING.HOME.HEADING3') }}</h2>
                <p class="mb-4 mb-md-5 home-desc">
                  {{ $t('ONBOARDING.HOME.DESCRIPTION') }}
                </p>
                <p class="mb-0 d-flex home-actions align-items-center">
                  <NuxtLink
                    :to="
                      addQueryParams('/login', {
                        queryParams,
                        allowedQueryParams
                      })
                    "
                    qid="home-2"
                    class="btn btn-white-bg btn-started c1-btn-disabled login-btn"
                    :class="{ 'c1-btn-enabled': gigyaLoaded }"
                  >
                    {{ $t('ONBOARDING.HOME.ALREADY_REGISTERED') }}
                  </NuxtLink>
                  <NuxtLink
                    qid="home-1"
                    to="/regoptions"
                    class="btn btn-white-bordered btn-started c1-btn-disabled ms-3 signup-btn"
                    :class="{ 'c1-btn-enabled': gigyaLoaded }"
                  >
                    {{ $t('ONBOARDING.HOME.GET_STARTED') }}
                  </NuxtLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="marketing-main-wrapper">
      <section class="pb-3 pb-xl-5 section-1">
        <div class="marketing-container container-fluid">
          <div class="marketing-wrapper">
            <h2 class="marketing-heading">
              {{ $t('ONBOARDING.HOME.MARKETING_HEADING') }}
            </h2>
            <div class="row">
              <div
                class="col-xl-4 d-flex justify-content-center flex-column text-center text-xl-left order-1 order-xl-0 marketing-text-container"
              >
                <div class="d-xl-flex align-items-center mt-4 mt-md-5 mt-xl-0">
                  <div class="sub-heading mt-2 mt-md-3 mt-xl-0">
                    {{ $t('ONBOARDING.HOME.MARKETING_MESSAGE1') }}
                  </div>
                </div>
                <div class="row g-0">
                  <div class="offset-md-2 col-md-8 offset-xl-0 col-xl-12">
                    <p class="mt-1 mt-md-1 sub-text">
                      {{ $t('ONBOARDING.HOME.MARKETING_DESC1') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 order-0 order-xl-1 text-center text-xl-left">
                <ResponsiveImage
                  file="all-in-one-place.webp"
                  mode="resolution"
                  :img="imagesProps.specialAboutCambridgeImage"
                  :screens="allInOneSectionScreens"
                  pc-image
                />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-xl-8 text-center text-xl-left">
                <ResponsiveImage
                  file="admin-made-easy.webp"
                  mode="resolution"
                  :img="imagesProps.specialAboutCambridgeImage"
                  :screens="adminMadeEasySectionScreens"
                  pc-image
                />
              </div>
              <div
                class="col-xl-4 d-flex justify-content-center flex-column text-center text-xl-left px-3 marketing-text-container"
              >
                <div class="d-xl-flex align-items-center mt-4 mt-md-5 mt-xl-0">
                  <div class="sub-heading mt-2 mt-md-3 mt-xl-0">
                    {{ $t('ONBOARDING.HOME.MARKETING_MESSAGE2') }}
                  </div>
                </div>
                <div class="row g-0">
                  <div class="offset-md-2 col-md-8 offset-xl-0 col-xl-12">
                    <p class="mt-1 sub-text">
                      {{ $t('ONBOARDING.HOME.MARKETING_DESC2') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-3 mb-md-5">
              <div
                class="col-xl-4 d-flex justify-content-center flex-column text-center text-xl-left order-1 order-xl-0 marketing-text-container"
              >
                <div
                  class="d-xl-flex align-items-center mt-4 mt-md-5 mt-xl-0 pt-2 pt-xl-0"
                >
                  <div class="sub-heading mt-2 mt-md-3 mt-xl-0">
                    {{ $t('ONBOARDING.HOME.MARKETING_MESSAGE3') }}
                  </div>
                </div>
                <div class="row g-0">
                  <div class="offset-md-2 col-md-8 offset-xl-0 col-xl-12">
                    <p class="mt-1 sub-text">
                      {{ $t('ONBOARDING.HOME.MARKETING_DESC3') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 order-0 order-xl-1 text-center text-xl-left">
                <ResponsiveImage
                  file="data-you-can-trust.webp"
                  mode="resolution"
                  :img="imagesProps.specialAboutCambridgeImage"
                  :screens="dataYouCanTrustSectionScreens"
                  pc-image
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-wrapper-video-banner">
        <ResponsiveImage
          file="video-banner.webp"
          :img="imagesProps['video-banner']"
          :screens="screens"
          pc-image
        />
        <div class="section-how-it-works">
          <h2>{{ $t('ONBOARDING.HOME.HOW_IT_WORKS') }}</h2>
          <div class="video-container">
            <div id="vid-plyr-iframe-container" class="ratio ratio-16x9">
              <div id="video-player-placeholder" />
            </div>
          </div>
        </div>
      </section>
      <!-- customer stories -->
      <section ref="customerStorySec" class="section-2 position-relative">
        <ResponsiveImage
          :img="imagesProps['customer-stories-bg']"
          file="customer-stories.webp"
          :screens="screens"
          pc-image
        />
        <div class="marketing-container container-fluid">
          <div class="marketing-wrapper p-0">
            <div class="marketing-heading position-relative">
              <h2 class="heading">
                {{ $t('ONBOARDING.HOME.CUSTOMER_STORIES_HEADING') }}
              </h2>
              <div class="sub-heading">
                <span>
                  {{ $t('ONBOARDING.HOME.CUSTOMER_STORIES_SUBHEADING') }}
                </span>
              </div>
            </div>
            <div
              class="row flex-row justify-content-center customers-review-container"
            >
              <!-- customer review cards -->
              <div
                v-for="(data, index) in JsonData"
                :key="data.institutionName + '-' + index"
                class="d-flex customer-review-card flex-column position-relative"
              >
                <div class="d-flex align-items-center">
                  <!-- customer image -->
                  <div class="d-inline-block customer-img-container me-3">
                    <ResponsiveImage
                      v-if="isCustStorySecVisible"
                      :file="data.img"
                      :img="imagesProps['customer-img']"
                      alt=""
                      :screens="customerImgs"
                      mode="static"
                      pc-image
                      @load="stop"
                    />
                  </div>
                  <!-- customer institution name and country -->
                  <div class="d-inline-block customer-info">
                    <div class="customer-instt-name">
                      <p class="mb-0">
                        {{ data.institutionName }}
                      </p>
                    </div>
                    <div class="customer-country">
                      <p class="mb-0">
                        {{ data.countryName }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- customer comment -->
                <div class="customer-comment">
                  {{ $t('ONBOARDING.HOME.' + data.comment) }}
                </div>
                <!-- read more -->
                <div class="readmore-container position-absolute">
                  <a class="readmore" :href="data.link" target="_blank"
                    >{{ $t('ONBOARDING.HOME.READ_MORE') }}
                    <nuxt-icon name="right-arrow" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script setup>
/**
 * imports
 */
import JsonData from '~/assets/data/customer-review.json';
import { useIntersectionObserver } from '@vueuse/core';

const runtimeConfig = useRuntimeConfig();
const { activateTheme } = useThemeService();

const customerStorySec = ref(null);
const isCustStorySecVisible = ref(false);
const route = useRoute();
const { addQueryParams } = useHttpHelper();
const queryParams = route.query;
const allowedQueryParams = runtimeConfig.public.ielts.allowedQueryParams;

/**
 * Use Intersection Observer to observe customer story section and show it when it is in viewport
 */
const { stop } = useIntersectionObserver(
  customerStorySec,
  ([{ isIntersecting }]) => {
    isCustStorySecVisible.value = isIntersecting;
  }
);

onBeforeMount(() => {
  activateTheme(runtimeConfig.public.themeTypes.adult.code);
});

onNuxtReady(() => {
  document.getElementById('vid-plyr-iframe-container').innerHTML =
    `<iframe src="https://www.youtube.com/embed/C3LqZTXBh7E?si=r-F-kK-5wJy1Vqas" loading="lazy" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`;
});

const imagesProps = {
  collage: {
    class: 'people-logo position-absolute',
    alt: 'Students Collage'
  },
  shield: {
    class: 'mb-3 mb-md-5 img-fluid shield-logo',
    alt: 'Shield'
  },
  'long-arrow-right': {
    class: 'ms-sm-2',
    'aria-hidden': 'true'
  },
  'background-home-page': {
    class: 'bg-image',
    alt: ''
  },
  'customer-img': {
    class: 'customer-img',
    alt: ''
  },
  'background-home-page-mobile': {
    class: 'mobile-bg-image',
    alt: ''
  },
  'customer-stories-bg': {
    class: 'position-absolute w-100 customer-stories-bg-image',
    alt: ''
  },
  specialAboutCambridgeImage: {
    alt: '',
    class: 'img-fluid mktng-img',
    loading: 'lazy'
  },
  userDesktop: {
    alt: 'User',
    class: 'd-none d-md-block',
    loading: 'lazy'
  },
  userMobile: {
    alt: 'User',
    class: 'd-md-none me-3',
    loading: 'lazy'
  },
  positiveReinforce: {
    loading: 'lazy',
    alt: 'Positive Reinforce'
  },
  analyticsDataIcon: {
    class: 'img-fluid mktng-img',
    alt: '',
    loading: 'lazy'
  },
  responsiveDeviceIcon: {
    class: 'img-fluid mktng-img',
    alt: '',
    loading: 'lazy'
  },
  ageAppropriateIcon: {
    class: 'img-fluid mktng-img',
    alt: '',
    loading: 'lazy'
  },
  'video-banner': {
    class: 'bg-image',
    alt: ''
  }
};

const screens = {
  mob: { size: '576' },
  tab: { size: '768' },
  desktop: { size: 'min' },
  iwb: { size: 'org' }
};

const heroSectionScreens = {
  ...screens,
  ...{
    mob: { size: '720' },
    tab: { size: '1280' }
  }
};

const adminMadeEasySectionScreens = {
  ...screens,
  ...{
    mob: { size: '1080' },
    tab: { size: '1104' }
  }
};

const allInOneSectionScreens = {
  ...screens,
  ...{
    mob: { size: '1080' },
    tab: { size: '1104' }
  }
};

const dataYouCanTrustSectionScreens = {
  ...screens,
  ...{
    mob: { size: '720' },
    tab: { size: '736' }
  }
};

const customerImgs = {
  mob: { size: 'org' },
  tab: { size: 'org' },
  desktop: { size: 'org' },
  iwb: { size: 'org' }
};

definePageMeta({
  middleware: ['page-redirection']
});
</script>

<style lang="scss" scoped>
#video-player-placeholder {
  background: lightgray;
}
.c1-onboarding-home-container {
  max-width: 100% !important;
}

.bg-wrapper-video-banner {
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;

  .bg-image {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}

.bg-wrapper {
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;

  .content-wrapper {
    z-index: 20;
    position: relative;
  }
  .bg-image {
    z-index: 1;
    width: 100%;
    min-height: 568px;
    @include media-breakpoint-up(md) {
      min-height: 350px;
    }
    @include media-breakpoint-up(lg) {
      min-height: 454px;
    }
  }
  @include media-breakpoint-up(lg) {
    flex: 1 1 auto;
  }

  @include media-breakpoint-up(xl) {
    flex: 1 0 auto;
  }

  &.content-wrapper {
    @include media-breakpoint-up(xl) {
      background-size: inherit;
    }
  }

  .home-wrapper {
    color: var(--cup-general-color-1);
    .main-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      @include media-breakpoint-up(md) {
        top: 50%;
        transform: translateY(-50%);
        margin-left: 20px;
      }
      @include media-breakpoint-up(xl) {
        margin-left: 40px;
      }
      .heading1 {
        color: #00b9b2;
        font-size: 32px;
        font-weight: 400;
        line-height: 40px;

        .heading2 {
          font-weight: 700;
        }
        @include media-breakpoint-up(lg) {
          font-size: 48px;
          font-weight: 400;
          line-height: 56px;
          padding: 4px 0;
        }
      }

      h2 {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 800;
        line-height: 24px;
        @include media-breakpoint-up(lg) {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 24px;
        }
      }

      .home-desc {
        font-size: 14px;
        line-height: 24px;
        max-width: 360px;
        @include media-breakpoint-up(lg) {
          font-size: 18px;
          line-height: 32px;
        }
        @include media-breakpoint-down(md) {
          max-width: 280px;
          margin: auto;
        }
      }

      .home-actions {
        justify-content: center;
        @include media-breakpoint-up(md) {
          justify-content: flex-start;
        }

        a {
          border-radius: 24px;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }
      }

      .login-btn {
        color: #19181a;
        background-color: #00b9b2;
        padding: 12px 46px;
        @include media-breakpoint-down(md) {
          padding: 12px 36px;
        }
      }

      .signup-btn {
        border: 2px solid #00b9b2;
        padding: 10px 36px;
        @include media-breakpoint-down(md) {
          padding: 12px 24px;
        }
      }
    }

    .shield-container {
      margin: 24px auto 0;

      @include media-breakpoint-up(md) {
        margin-top: 56px;
      }

      @include media-breakpoint-between(md, lg) {
        max-width: 500px;
      }

      @include media-breakpoint-up(xl) {
        margin: 0;
      }

      .shield-logo {
        @include media-breakpoint-up(md) {
          position: relative;
          left: 15px;
        }
      }

      .people-logo {
        max-width: 130%;
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
        z-index: 1;

        @include media-breakpoint-up(md) {
          max-width: initial;
          max-width: 656px;
        }
      }
    }
  }
}

.marketing-main-wrapper {
  font-family: Mulish, Sans-serif;
  background: #f3f6f6;
}

.marketing-container {
  padding-left: 15px;
  padding-right: 15px;

  .marketing-wrapper {
    color: var(--cup-general-color-5);

    @include media-breakpoint-up(md) {
      margin: 0 25px;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 65px;
    }

    .marketing-heading {
      padding: 64px 0;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 40px;
      }
      @include media-breakpoint-down(sm) {
        max-width: 280px;
        margin: auto;
      }
    }
    .marketing-text-container {
      @include media-breakpoint-down(sm) {
        margin: auto;
        max-width: 302px;
      }
    }
    .sub-heading {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;

      @include media-breakpoint-up(xl) {
        text-align: left;
      }
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }

    .sub-text {
      font-size: 16px;
      line-height: 24px;
      text-align: center;

      @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 32px;
      }

      @include media-breakpoint-up(xl) {
        text-align: left;
      }
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }

    .mktng-img {
      @include media-breakpoint-up(md) {
        max-width: 688px;
      }

      @include media-breakpoint-up(lg) {
        max-width: 736px;
      }
    }

    .customer-review {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
      border-radius: 16px;
      padding: 20px 16px;
      margin-bottom: 16px;
      background-color: var(--cup-general-color-1);

      @include media-breakpoint-up(md) {
        padding: 32px;
      }

      img {
        flex-shrink: 0;
        max-width: 80px;
      }

      .review-text {
        font-size: 21px;
        font-weight: 300;
        line-height: 32px;

        @include media-breakpoint-up(md) {
          font-size: 32px;
          line-height: 40px;
        }
      }

      .customer-info {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }

    .reinforce-img {
      height: 360px;
    }

    .satisfied-customer {
      font-size: 32px;
      font-weight: 300;
      line-height: 48px;

      @include media-breakpoint-up(md) {
        font-size: 40px;
        line-height: 56px;
      }
    }
  }
}

.section-1 {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
  position: relative;
}

.section-2 {
  padding-bottom: 45px;
  background-color: var(--cup-general-color-1);
  @include media-breakpoint-up(md) {
    padding-bottom: 66px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 74px;
  }
  .customer-stories-bg-image {
    z-index: 0;
    bottom: 0;
  }
  .marketing-container {
    @include media-breakpoint-up(lg) {
      max-width: 1340px; // 1276px (3 cards in a row) + 64px (padding - 32px)
      padding: 0;
      margin: 0 auto;
    }
    .marketing-wrapper {
      @media (min-width: 768px) {
        margin: 0;
      }
      .marketing-heading {
        text-align: center;
        margin-bottom: 0;
        padding: 48px 0 32px;
        background-color: #ffffff;
        color: #000000;
        @include media-breakpoint-up(md) {
          padding: 66px 0 42px;
        }

        .heading {
          font-size: 24px;
          line-height: 30.12px;
          font-weight: 700;
          @include media-breakpoint-up(md) {
            font-size: 36px;
            line-height: 44px;
          }
        }
        .sub-heading {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          width: 290px;
          margin: 12px auto 0;
          text-align: center;
          @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 30px;
            width: 510px;
            margin: 16px auto 0;
          }
          @media (min-width: 1280px) {
            width: 682px;
          }
        }
      }

      .customers-review-container {
        gap: 16px;
        font-weight: 400;
        color: #000000;
        @include media-breakpoint-up(sm) {
          margin: 0 20px;
        }
        @include media-breakpoint-up(md) {
          gap: 20px;
        }
        @media (min-width: 1280px) {
          gap: 24px;
        }
        .customer-review-card {
          background-color: #ffffff;
          .customer-img-container {
            width: 68px;
            height: 68px;
            margin-bottom: auto;
            .customer-img {
              width: 68px;
              height: 68px;
            }
          }
          width: 320px;
          padding: 18px 14px;
          box-sizing: border-box;
          border: 2px solid #00b9b2;
          border-radius: 10px;
          @media (min-width: 1280px) {
            padding: 22px 22px;
            width: 384px;
            min-height: 328px;
          }
          .customer-info {
            line-height: 28px;
            font-size: 18px;
            .customer-instt-name {
              font-weight: 700;
              @media (min-width: 1280px) {
                font-size: 20px;
                line-height: 30px;
              }
            }
            .customer-country {
              font-style: italic;
              color: #6e6e6e;
              margin-top: -3px;
              @media (min-width: 1280px) {
                font-size: 20px;
                line-height: 30px;
              }
            }
          }
          .customer-comment {
            font-size: 16px;
            line-height: 24px;
            margin: 16px 0 40px;
            min-height: 120px;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            line-clamp: 5;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            @media (min-width: 1280px) {
              font-size: 20px;
              line-height: 30px;
              min-height: 150px;
              width: 336px;
            }
          }
          .readmore-container {
            bottom: 18px;
            @media (min-width: 1280px) {
              bottom: 22px;
            }
            .readmore {
              font-weight: 700;
              line-height: 24px;
              color: #133844;
              position: relative;
              height: fit-content;
              padding-top: 2px;
              padding-bottom: 2px;
              .nuxt-icon {
                position: absolute;
                padding: 0 2px;
                font-size: 20px;
                top: -1px;
              }
            }
          }
        }
      }
    }
  }
}

.section-how-it-works {
  text-align: center;
  padding: 94px 0px;
  color: white;
  z-index: 2;

  h2 {
    margin: 0;
    font-size: 36px;
  }

  @media screen and (max-width: 992px) {
    padding: 64px 36px;
  }

  @media screen and (max-width: 576px) {
    padding: 48px 24px;
    @media screen and (max-width: 360px) {
      padding: 36px 15px;
    }
    h2 {
      font-size: 24px;
    }
  }

  .video-container {
    max-width: 938px;
    margin: auto;
    padding: 58px 0 28px;

    @media screen and (max-width: 992px) {
      max-width: 840px;
      padding: 50px 0 14px 0;
    }

    @media screen and (max-width: 576px) {
      max-width: 484px;
      padding: 46px 0 10px 0;
    }

    @media screen and (max-width: 360px) {
      max-width: 310px;
      padding: 46px 0 10px 0;
    }
  }
}
</style>
